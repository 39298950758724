<script>
const decimalFormat = new Intl.NumberFormat('th-TH', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 5 })
</script>
<script setup>
import {computed, ref, reactive, onMounted} from "vue";
import {useToast} from "primevue/usetoast";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {storeToRefs} from "pinia";
import {CalculatorStore} from "@/uhas/store/calculator";

const toast = useToast();
const calculatorStore = CalculatorStore();

/**
 * Data
 */
const blockOverlay = ref(false);

const {configs: calculatorConfigs} = storeToRefs(calculatorStore);

const orderType = ref("Buy");
const basicInfoState = reactive({
	portInvest: null,
	currentPrice: null,
	profitPrice: null,
	lossPrice: null,
	forexContractSize: null,
	forexLeverage: null,
	lots: null,
});
const basicInfoValidation = useVuelidate({
	portInvest: {
		required,
		gtZero: v => v > 0,
		minmax: v => (v > (basicInfoState.lossPrice || 0)),
	},
	currentPrice: {
		required
	},
	profitPrice: {
		required,
	},
	lossPrice: {
		required,
		minmax: v => (v <= (basicInfoState.portInvest || Number.MAX_VALUE)),
	},
	forexContractSize: { required },
	forexLeverage: { required },
	lots: { required },
}, basicInfoState);
const basicInfoSubmitted = ref(false);

const calculatedModal = ref(false);


/**
 * Computed
 */
const forexSymbolsContractSize = computed(() => {
	if (!calculatorConfigs.value?.forexSymbolsContractSize) {
		return [];
	}

	let groupedSymbol = [],
	sortedSymbols = calculatorConfigs.value?.forexSymbolsContractSize,
	prevSymbol = null,
	i = -1;
	sortedSymbols.sort((a, b) => ((a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0)));
	for (const symbol of sortedSymbols) {
		if (prevSymbol !== symbol.category) {
			prevSymbol = symbol.category;
			groupedSymbol[++i] = {
				label: symbol.category,
				items: [{...symbol
					// label: symbol.symbol,
					// value: symbol.contract_size
				}]
			};
		} else {
			groupedSymbol[i].items.push({...symbol
				// label: symbol.symbol,
				// value: symbol.contract_size
			});
		}
	}

	return groupedSymbol;
});
const pricePercentIncrease = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return 100 * basicInfoState.profitPrice / basicInfoState.portInvest;
});
const pricePercentDecrease = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return 100 * basicInfoState.lossPrice / basicInfoState.portInvest;
});
const newProfitPrice = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return 4 * basicInfoState.lossPrice;
});
const newProfitHalf = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return 2 * basicInfoState.lossPrice;
});
const newLossPrice = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return 2 * basicInfoState.portInvest / 100;
});
const overTradeTimes = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return (basicInfoState.lots * basicInfoState.forexContractSize?.contract_size * basicInfoState.currentPrice) / basicInfoState.portInvest;
});
const trade100 = computed(() => {
	const allFee = 100 * 0.01 * (basicInfoState.lots * basicInfoState.forexContractSize?.contract_size * basicInfoState.currentPrice);
	const win50loss50 = (basicInfoState.profitPrice * 50) - (basicInfoState.lossPrice * 50) - allFee;
	const inaccurate20 = win50loss50 * 0.8; // 20% off
	const bluePortPrevent = (basicInfoState.profitPrice * 50 * 0.85) - (basicInfoState.lossPrice * 50) - allFee;
	const avarage = (win50loss50 + inaccurate20 + bluePortPrevent) / 3;

	return 100 * (basicInfoState.portInvest + avarage) / basicInfoState.portInvest;
});
const trade100WithAdvice = computed(() => {
	const allFee = 100 * 0.0001 * (level0.value * basicInfoState.forexContractSize?.contract_size * basicInfoState.currentPrice);
	const win50loss50 = (basicInfoState.lossPrice * 4 * 50) - (basicInfoState.lossPrice * 50) - allFee;
	const inaccurate20 = win50loss50 * 0.8; // 20% off
	const bluePortPrevent = (basicInfoState.lossPrice * 4 * 50 * 0.85) - (basicInfoState.lossPrice * 50) - allFee;
	const avarage = (win50loss50 + inaccurate20 + bluePortPrevent) / 3;

	return 100 * (basicInfoState.portInvest + avarage) / basicInfoState.portInvest;
});
const risks = computed(() => {
	if (basicInfoState.forexContractSize?.category === "Forex") {
		return [20, 5, 50, 100, 150]
	} else if (basicInfoState.forexContractSize?.category === "คริปโตฯ") {
		return [1.75, 1, 5, 10, 15]
	} else if (basicInfoState.forexContractSize?.category === "หุ้น") {
		return [3, 1.5, 8, 15, 30]
	} else if (basicInfoState.forexContractSize?.category === "พลังงาน") {
		return [2, 1, 7.5, 12, 20]
	} else if (basicInfoState.forexContractSize?.category === "โลหะ") {
		return [6, 3, 15, 30, 75]
	}

	return [0, 0, 0, 0, 0];
});
const currentPriceInUSD = computed(() => basicInfoState.currentPrice * (basicInfoState.forexContractSize?.y || 1));
// const profitPriceInUSD = computed(() => basicInfoState.profitPrice * (basicInfoState.forexContractSize?.y || 1));
// const lossPriceInUSD = computed(() => basicInfoState.lossPrice * (basicInfoState.forexContractSize?.y || 1));
const level0 = computed(() => Math.floor(risks.value[0] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
// const level1 = computed(() => Math.floor(risks.value[1] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
// const level2 = computed(() => Math.floor(risks.value[2] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
// const level3 = computed(() => Math.floor(risks.value[3] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
// const level4 = computed(() => Math.floor(risks.value[4] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
// const notEnoughCapital = computed(() => {
// 	const msg = "จำนวนเงินลงทุนของคุณ ไม่เพียงพอสำหรับความเสี่ยง ระดับ ";
// 	const lots = [
// 		// {level: 0, lot: level0.value},
// 		{level: 1, lot: level1.value},
// 		{level: 2, lot: level2.value},
// 		{level: 3, lot: level3.value},
// 		{level: "สูงสุด", lot: level4.value},
// 	];
// 	const highRisks = lots.filter(item => item.lot < 0.01);

// 	return highRisks.length === 0 ? "" : msg + highRisks.map(item => item.level).join(" / ระดับ ");
// });
// const highRiskLots = computed(() => {
// 	const msg = "คุณไม่ควรเทรด ด้วยขนาด Lot ขนาดความเสี่ยง ระดับ ";
// 	const risksLevel = [
// 		// {level: 0, risk: stopLoss(level0.value) * 100 / basicInfoState.portInvest},
// 		{level: 1, risk: stopLoss(level1.value) * 100 / basicInfoState.portInvest},
// 		{level: 2, risk: stopLoss(level2.value) * 100 / basicInfoState.portInvest},
// 		{level: 3, risk: stopLoss(level3.value) * 100 / basicInfoState.portInvest},
// 		{level: "สูงสุด", risk: stopLoss(level4.value) * 100 / basicInfoState.portInvest},
// 	];
// 	const highRisks = risksLevel.filter(item => item.risk < -8);

// 	return highRisks.length === 0 ? "" : msg + highRisks.map(item => item.level).join(" / ระดับ ");
// });


/**
 * Mounted
 */
onMounted(() => {
	calculatorStore.getConfigs()
		.then(() => {
			// Set default of forexLeverage
			if (calculatorConfigs.value?.forexLeverage) {
				if (calculatorConfigs.value?.forexLeverage.indexOf('500') !== -1) {
					basicInfoState.forexLeverage = '500';
				} else {
					basicInfoState.forexLeverage = calculatorConfigs.value?.forexLeverage[0];
				}
			}
		})
		.catch(() => {
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: "There is a problem with retrieving calculator config.",
				life: 20000
			});
		})
});


/**
 * Actions
 */
const updateContractSize = () => {
	if (basicInfoState.forexContractSize?.type) {
		const currentPrice = parseFloat(basicInfoState.forexContractSize?.x || 1) / parseFloat(basicInfoState.forexContractSize?.y || 1);
		basicInfoState.currentPrice = currentPrice;
	}
}
const lotsCalculate = () => {
	basicInfoSubmitted.value = true;
	if (basicInfoValidation.value.$invalid) {
		return;
	}

	calculatedModal.value = true;
}
const numberFormat = v => decimalFormat.format(v);
</script>

<style lang="scss">
.block-overlay {
	box-shadow: unset;

	.p-dialog-content {
		background: transparent;
    overflow: hidden;

		circle {
			fill: none;
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep input {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #bbb;
		opacity: 1; /* Firefox */
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #bbb;
	}
	&::-ms-input-placeholder { /* Microsoft Edge */
		color: #bbb;
	}
}

::v-deep .p-card-body {
	padding: 2rem;
}
::v-deep.advice-card .p-card-body {
	padding: 1rem 1rem 0 1rem;
}
</style>

<template>
	<Toast />
	<ConfirmPopup />
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<Card>
		<template #title>
      การวางแผน การออกออเดอร์
    </template>
		<template #content>
			<!-- <div class="font-semibold text-xl">
				ภาพรวมพอร์ตการเทรดตอนนี้
			</div> -->
			<Divider
				align="left"
				type="dashed"
				class="text-lg mt-5 mb-4"
			>
				<b>ภาพรวมพอร์ตการเทรดตอนนี้</b>
			</Divider>

			<div class="w-full md:w-25rem mt-5">
				<div class="flex justify-content-between mt-1">
					<span class="align-self-center">
						ความต้องการ
					</span>
					<SelectButton
						v-model="orderType"
						:options="['Buy', 'Sell']"
					/>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-float-label">
						<Dropdown
							v-model="basicInfoValidation.forexContractSize.$model"
							:class="{'p-invalid':basicInfoValidation.forexContractSize.$invalid && basicInfoSubmitted, 'flex-grow-1': true}"
							:options="forexSymbolsContractSize"
							:filter="true"
							optionLabel="symbol"
							optionGroupLabel="label"
							optionGroupChildren="items"
							id="forexContractSize"
							placeholder="คู่เงิน"
							@change="updateContractSize"
						>
							<template #optiongroup="{option}">
								<div class="mt-3 text-xl">
									{{ option.label }}
								</div>
							</template>
						</Dropdown>
						<!-- <label for="forexContractSize">คู่เงิน</label> -->
					</span>

					<span class="p-float-label">
						<Dropdown
							v-model="basicInfoValidation.forexLeverage.$model"
							:class="{'p-invalid':basicInfoValidation.forexLeverage.$invalid && basicInfoSubmitted, 'flex-grow-1': true}"
							:options="calculatorConfigs?.forexLeverage || []"
							id="forexLeverage"
							placeholder="Leverage"
						>
							<template #option="{option}">
								1:{{ option }}
							</template>
							<template #value="{value}">
								1:{{ value }}
							</template>
						</Dropdown>
						<!-- <label for="forexLeverage">Leverage</label> -->
					</span>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						เงินลงทุนของคุณ
					</span>
					<InputNumber
						v-model="basicInfoValidation.portInvest.$model"
						:class="{'p-invalid':basicInfoValidation.portInvest.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						:disabled="!basicInfoState.forexContractSize"
						suffix=" $"
						currency="USD"
						locale="en-US"
					/>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						ราคาปัจจุบัน
					</span>
					<InputNumber
						v-model="basicInfoValidation.currentPrice.$model"
						:class="{'p-invalid':basicInfoValidation.currentPrice.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="5"
						:disabled="!basicInfoState.forexContractSize"
						:suffix="` ${basicInfoState.forexContractSize?.currency || '$'}`"
						currency="USD"
						locale="en-US"
					/>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						กำไรที่ต้องการ
					</span>
					<InputNumber
						v-model="basicInfoValidation.profitPrice.$model"
						:class="{'p-invalid':basicInfoValidation.profitPrice.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="5"
						:disabled="!basicInfoState.forexContractSize"
						:suffix="` ${basicInfoState.forexContractSize?.currency || '$'}`"
						currency="USD"
						locale="en-US"
					/>
				</div>
				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						ขาดทุนที่ยอมรับได้
					</span>
					<InputNumber
						v-model="basicInfoValidation.lossPrice.$model"
						:class="{'p-invalid':basicInfoValidation.lossPrice.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="5"
						:disabled="!basicInfoState.forexContractSize"
						:suffix="` ${basicInfoState.forexContractSize?.currency || '$'}`"
						currency="USD"
						locale="en-US"
					/>
				</div>
				<Message
					v-if="basicInfoState.lossPrice > basicInfoState.portInvest"
					:closable="false"
					:sticky="true"
					severity="warn"
					class="my-1"
				>
					ไม่สามารถขาดทุนได้มากกว่าเงินลงทุนของคุณ
				</Message>
				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						จำนวน Lot
					</span>
					<InputNumber
						v-model="basicInfoValidation.lots.$model"
						:class="{'p-invalid':basicInfoValidation.lots.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						:disabled="!basicInfoState.forexContractSize"
						id="lots"
						locale="en-US"
						aria-describedby="lots-help"
					/>
				</div>
				<small id="lots-help" class="block text-500">
					หากคุณไม่แน่ใจขนาด Lot ที่เหมาะสม ให้ Uhas ประเมินให้ได้
					<router-link :to="{name: 'lots-calculator'}">
						คลิกที่นี่
					</router-link>
				</small>

				<Button
					label="คำนวณ"
					class="mt-4"
					@click="lotsCalculate"
				/>
				<Dialog
					v-model:visible="calculatedModal"
					header="ภาพรวมพอร์ตการเทรดตอนนี้"
					:breakpoints="{'960px': '75vw', '640px': '90vw'}"
					:style="{width: '50vw'}"
					:modal="true"
				>
          <Divider
						align="left"
						type="dashed"
						class="text-lg mt-5 mb-4"
					>
						<b>ภาพรวม</b>
					</Divider>
					<p>
						พอร์ตลงทุนตอนนี้อยู่ที่
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(basicInfoState.portInvest) }} $</span>
					</p>
					<p>
						คุณต้องการกำไรอยู่ที่
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(Math.floor(10000 * basicInfoState.profitPrice / basicInfoState.portInvest) / 100) }} %</span>
						<br>
						และยอมรับขาดทุน
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(Math.floor(10000 * basicInfoState.lossPrice / basicInfoState.portInvest) / 100) }} %</span>
						<br>
						จากออเดอร์นี้
					</p>
					<p>
						กรณีที่เลวร้าย เมื่อมีการขาดทุน
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ Math.ceil(basicInfoState.portInvest / basicInfoState.lossPrice) }} </span>
						ครั้งติดต่อกันพอร์ตนี้จะแตกทันที
					</p>
					<!-- <p>
						หากราคาเคลื่อนที่ {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(pricePercentIncrease) }} %</span>
						คุณจะได้กำไรจาก Take Profit ทันที
						<br>
						หากราคาเคลื่อนที่ {{ orderType === "Buy" ? "ลง" : "ขึ้น" }}
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(pricePercentDecrease) }} %</span>
						คุณจะขาดทุนจาก Stop Loss ทันที
					</p> -->

					<Divider
						align="left"
						type="dashed"
						class="text-lg mt-5 mb-4"
					>
						<b>วิเคราะห์จุดอ่อน</b>
					</Divider>

					<p>
						ในตอนนี้ คุณ Overtrade จำนวน
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(overTradeTimes) }}</span>
						เท่า ของเงินลงทุนทั้งหมด
						<br>
						และค่า Lot ที่เหมาะสม คือ
						<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(level0) }}</span>
					</p>

					<p v-if="pricePercentDecrease <= 0.5">
						พอร์ตของคุณมี Stop Loss แคบเกินไป ที่จำนวน
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(pricePercentDecrease) }} %</span>
						<br>
						อาจไม่เพียงพอต่อความผันผวนในตลาด
					</p>

					<p v-if="pricePercentIncrease <= 1">
						พอร์ตของคุณมี Take Profit แคบเกินไป ที่จำนวน
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(pricePercentIncrease) }} %</span>
						<br>
						อาจไม่คุ้มค่าในการตั้งเป้าหมายกำไร
					</p>

					<p v-if="basicInfoState.profitPrice / basicInfoState.lossPrice > 4">
						คุณอาจตั้ง Reward : Risk สูงเกินไป ที่
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.profitPrice / basicInfoState.lossPrice) }} : 1</span>
						<br>
						อาจจะทำให้มีโอกาสได้กำไร มีความน่าจะเป็นต่ำเกินไป
					</p>

					<p v-if="basicInfoState.profitPrice / basicInfoState.lossPrice < 0.5">
						คุณอาจตั้ง Reward : Risk ต่ำเกินไป ที่
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.profitPrice / basicInfoState.lossPrice) }} : 1</span>
						<br>
						อาจจะทำให้โอกาสได้กำไร ไม่คุ้มกับจำนวนเงินที่ขาดทุน
					</p>

					<p v-if="basicInfoState.portInvest / basicInfoState.lossPrice < 10">
						คุณตั้ง Stop Loss จำนวนเงินเยอะเกินไป เมื่อขาดทุนติดต่อกันไม่ถึง
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ Math.ceil(basicInfoState.portInvest / basicInfoState.lossPrice) }}</span>
						ครั้ง
						<br>
						พอร์ตของคุณจะแตกในทันที
					</p>

					<Divider
						align="left"
						type="dashed"
						class="text-lg mt-5 mb-4"
					>
						<b>Uhas.com ช่วยวางแผน</b>
					</Divider>

					<p><b class="font-italic">การบริหารการเทรดที่เหมาะสม</b></p>

					<p>
						พอร์ตลงทุนตอนนี้อยู่ที่
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(basicInfoState.portInvest) }} $</span>
					</p>

					<p v-if="level0 !== basicInfoState.lots">
						คุณควรปรับขนาด Lots ให้เป็น
						<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(level0) }}</span>
						Lot
					</p>

					<p v-if="pricePercentDecrease <= 0.5">
						ยอมรับขาดทุน
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.lossPrice) }} $</span>
						<br>
						คุณอาจจะต้องปรับจุด Stop Loss เป็นจำนวนเงิน
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(newLossPrice) }} $</span>
						<br>
						สังเกตุได้ว่า Stop Loss ใหม่จะมีการทนแรงสวิงได้มากกว่าเดิม
						<br>
						ถึง
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(100 * (newLossPrice - basicInfoState.lossPrice) / basicInfoState.lossPrice) }} %</span>
						ซึ่งนั่นปลอดภัยกับการเทรดมากกว่าเดิม
					</p>

					<template v-if="basicInfoState.profitPrice / basicInfoState.lossPrice > 2.5">
						<p>
							ตอนนี้คุณตั้ง Reward / Risk อยู่ที่
							<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.profitPrice / basicInfoState.lossPrice) }} : 1</span>
							<br>
							ซึ่งนั้นอาจจะมีความเป็นไปได้ยาก
							<br>
							คุณอาจจะต้องลดลงมาให้เหลือประมาณ
							<span class="text-cyan-500 hover:text-cyan-700 font-semibold">2.5 : 1</span>
						</p>

						<p><b class="font-italic">การลดกำไร</b></p>

						<p>
							คุณอาจจะต้องลดกำไรลงให้เหลือ
							<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(newProfitPrice) }} $</span>
							<br>
							และยอมรับขาดทุนที่
							<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.lossPrice) }} $</span>
							<br>
							เพื่อให้คุณได้มีโอกาสได้เก็บกำไรได้เร็วมากขึ้น
							<br>
							และจะทำให้เป้าหมายในการทำกำไรของคุณสั้นลง
						</p>

						<p>
							จากเดิมราคาเคลื่อน {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}
							<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(pricePercentIncrease) }} %</span>
							คุณจะได้กำไรจาก Take Profit ทันที
							<br>
							ค่าใหม่ราคาเคลื่อน {{ orderType === "Buy" ? "ขึ้น" : "ลง" }} แค่
							<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(100 * newProfitPrice / basicInfoState.portInvest) }} %</span>
							คุณจะได้กำไรจาก Take Profit ทันที
							<br>
							เห็นไหมว่า โอกาสที่ราคาจะไปโดน Take Profit ก็ง่ายขึ้น
							<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(100 * (basicInfoState.profitPrice - newProfitPrice) / basicInfoState.profitPrice) }} %</span>
						</p>
					</template>

					<template v-if="basicInfoState.profitPrice / basicInfoState.lossPrice < 0.5">
						<p>
							ตอนนี้คุณตั้ง Reward / Risk อยู่ที่
							<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.profitPrice / basicInfoState.lossPrice) }} : 1</span>
							<br>
							อาจจะทำให้คุณได้กำไรไม่คุ้มเสีย
							<br>
							คุณอาจจะต้องเพิ่ม Reward ขึ้นมาอย่างน้อยประมาณ
							<span class="text-green-500 hover:text-green-700 font-semibold">0.5 : 1</span>
						</p>

						<p><b class="font-italic">การเพิ่มกำไร</b></p>

						<p>
							คุณอาจจะต้องเพิ่มกำไรเป็น
							<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(newProfitHalf) }} $</span>
							<br>
							และยอมรับขาดทุนที่
							<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.lossPrice) }} $</span>
							<br>
							เพื่อให้คุณได้มีโอกาสได้เก็บกำไรได้เร็วมากขึ้น
							<br>
							และจะทำให้เป้าหมายในการทำกำไรของคุณสั้นลง
						</p>

						<p>
							จากเดิมคุณต้องได้กำไร จำนวน
							<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ Math.ceil(basicInfoState.lossPrice / basicInfoState.profitPrice) }}</span>
							ออเดอร์ จึงคุ้มต่อการขาดทุน 1 ครั้ง
							<br>
							ค่าใหม่คุณทำกำไรได้แค่ จำนวน
							<span class="text-green-500 hover:text-green-700 font-semibold">2</span>
							ออเดอร์ จึงคุ้มต่อการขาดทุน 1 ครั้ง
							<br>
							เห็นไหมว่า โอกาสที่คุณจะได้กำไรก็ง่ายขึ้น
						</p>

					</template>


					<Divider
						align="left"
						type="dashed"
						class="text-lg mt-5 mb-4"
					>
						<b>ประเมินสุขภาพพอร์ตลงทุนตามที่ Uhas.com</b>
					</Divider>

					<p><b class="font-italic">ค่าเดิม</b></p>

					<p>
						ทุน
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(basicInfoState.portInvest) }} $</span>
						เทรด
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(basicInfoState.lots) }}</span>
						Lot
						<br>
						คุณตั้ง Reword / Risk ไว้ที่
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(basicInfoState.profitPrice / basicInfoState.lossPrice) }} : 1</span>
						<br>
						หากราคาเคลื่อนที่ถึง Take Profit พอร์ตจะโตขึ้น
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(Math.floor(10000 * basicInfoState.profitPrice / basicInfoState.portInvest) / 100) }} %</span>
						<br>
						หากราคาเคลื่อนที่ถึง Stop Loss พอร์ตจะลดลง
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(Math.floor(10000 * basicInfoState.lossPrice / basicInfoState.portInvest) / 100) }} %</span>
						<br>
						<br>
						ประเมินจากความน่าจะเป็นจาก Uhas หากทำการเทรด 100 ครั้ง
						<br>
						ผลลัพธ์ ความน่าจะเป็นกำไรอยู่ที่
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(trade100) }} %</span>
					</p>

					<p><b class="font-italic">ค่าใหม่ที่แนะนำ</b></p>

					<p>
						ทุน
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(basicInfoState.portInvest) }} $</span>
						แนะนำ เทรด
						<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(level0) }}</span>
						Lot
						<br>
						แนะนำ Reword / Risk ไว้ที่
						<span class="text-green-500 hover:text-green-700 font-semibold">2.5 : 1</span>
						<br>
						หากราคาเคลื่อนที่ถึง Take Profit พอร์ตจะโตขึ้น
						<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(Math.floor(10000 * 2.5 * basicInfoState.lossPrice / basicInfoState.portInvest) / 100) }} %</span>
						<br>
						หากราคาเคลื่อนที่ถึง Stop Loss พอร์ตจะลดลง
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(Math.floor(10000 * basicInfoState.lossPrice / basicInfoState.portInvest) / 100) }} %</span>
						<br>
						<br>
						ประเมินจากความน่าจะเป็นจาก Uhas ค่าที่แนะนำหากทำการเทรด 100 ครั้ง
						<br>
						ผลลัพธ์ ความน่าจะเป็นกำไรอยู่ที่
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(trade100WithAdvice) }} %</span>
					</p>

					<template #footer>
						<Button
							label="ตกลง"
							icon="pi pi-check"
							autofocus
							@click="calculatedModal = false"
						/>
					</template>
        </Dialog>
			</div>
		</template>
	</Card>
</template>
