import {defineStore} from "pinia";
import {getDatabase, ref, onValue} from "firebase/database";

const database = getDatabase();

export const CalculatorStore = defineStore('calculator', {

	// persist: {
	// 	paths: ['user']
	// },

  state: () => ({
		allConfigs: null,
		configSnap: false
	}),

	getters: {
		configs: state => state.allConfigs,
	},

	actions: {
		getConfigs () {
			return new Promise((resolve) => {
				if (!this.configSnap) {
					onValue(ref(database, 'configs'), snapshot => {
						this.allConfigs =  snapshot.val();
						this.configSnap = true;

						resolve(this.configs)
					});
				} else {
					resolve(this.configs)
				}
				// if (!this.configs || names.find(name => !this.configs[name])) {
				// 	get(child(ref(database), 'configs')).then(snapshot => {
				// 		if (snapshot.exists()) {
				// 			this.configs = snapshot.val();

				// 			resolve(
				// 				names.reduce((prev, curr) => ({[curr]: this.configs[curr], ...prev }), {})
				// 			);
				// 		} else {
				// 			reject(null);
				// 		}
				// 	}).catch(err => {
				// 		console.error(err);
				// 		reject(err);
				// 	});
				// } else {
				// 	resolve(
				// 		names.reduce((prev, curr) => ({[curr]: this.configs[curr], ...prev }), {})
				// 	);
				// }
			});
		},
	}
})
